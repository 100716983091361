<template>
  <div class="search">
    <div class="search_top">
      <van-search
        autofocus="autofocus"
        class="search-input"
        v-model="value"
        :clearable="false"
        placeholder="搜索币种/平台"
        @search="onSearch"
      />
      <p @click="goBack">取消</p>
    </div>

    <div @click="cancelVal" v-if="flag" class="search_close">
      <img src="../../assets/img/search_close.png" alt="" />
    </div>
    <div v-if="serchShow" class="hotSearch">
      <p>热门搜索</p>
      <ul>
        <li @click="changeVal(item)" v-for="(item, index) in list" :key="index">
          {{ item.currencyCode }}
        </li>
      </ul>
    </div>

    <div v-if="!serchShow" class="search_val">
      <div class="market_nav">
        <div
          class="nav_item"
          :class="[item.index == tableIndex ? 'item_on' : 'item_off']"
          v-for="item in navList"
          :key="item.index"
          @click="tabClick(item)"
        >
          <p v-if="item.index == 0">{{ item.label }}({{ totalNum }})</p>
          <p v-if="item.index == 1">{{ item.label }}({{ totalNumH }})</p>
          <img
            v-if="item.index == tableIndex"
            class="line"
            src="../../assets/img/home_tab_sel.png"
            alt
          />
        </div>
      </div>
      <div v-if="switchList == 'marketValue'" class="market_table">
        <div class="market_table_title">
          <ul>
            <li
              class="tableTitile"
              v-for="item in tableTitile"
              :key="item.index"
            >
              <div class="item">
                <p>{{ item.label }}</p>
              </div>
            </li>
          </ul>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="noData" v-if="noData">
            <img src="../../assets/img/empty.png" alt="" />
            <p>未搜索到您要的数据</p>
          </div>
          <template>
            <van-list
              v-model="loading"
              :finished="finished"
              @load="onLoad"
              :offset="100"
            >
              <div class="table_box">
                <div
                  class="table_item"
                  @click="godetail(item)"
                  v-for="(item, index) in listSearch"
                  :key="index"
                >
                  <div class="number">
                    <div class="pic">
                      <img :src="item.logoUrl" alt />
                      <span>{{ item.currencyCode }}</span>
                    </div>
                    <div style="padding-top:10px" class="current_number">
                      <p>{{ item.marketCap | formateMoneyY }}</p>
                      <span>{{ currentCurrency }}</span>
                    </div>
                  </div>
                  <div class="glob_number">
                    <p
                      :class="[
                        item.changePercentage < 0
                          ? 'glob_number_top'
                          : 'glob_number_top_G'
                      ]"
                    >
                      {{ item.price | formateMoneyG }}
                    </p>
                    <p class="glob_number_bottom">
                      {{ item.price | formateMoneyU }}
                    </p>
                  </div>
                  <div class="TF_number">
                    <p class="colorH" v-if="item.changePercentage == 0">
                      {{ item.changePercentage | toDecomal }}%
                    </p>
                    <p
                      v-else
                      :class="[
                        item.changePercentage < 0 ? 'colorRed' : 'colorGreen'
                      ]"
                    >
                      <span v-if="item.changePercentage > 0"
                        >+{{ item.changePercentage | toDecomal }}%</span
                      >
                      <span v-else
                        >{{ item.changePercentage | toDecomal }}%</span
                      >
                    </p>
                    <!-- <p></p> -->
                  </div>
                </div>
              </div>
            </van-list>
          </template>
        </van-pull-refresh>
      </div>
      <div v-if="switchList == 'hotSearch'" class="market_table">
        <van-pull-refresh v-model="isLoadingH" @refresh="onRefreshH">
          <div class="noData" v-if="noDataH">
            <img src="../../assets/img/empty.png" alt="" />
            <p>未搜索到您要的数据</p>
          </div>
          <template>
            <van-list
              v-model="loadingH"
              :finished="finishedH"
              @load="onLoadH"
              :offset="100"
            >
              <div mhm class="table_box">
                <div
                  class="table_item"
                  @click="godetailT(item.exchangeId)"
                  v-for="(item, index) in listSearchH"
                  :key="index"
                >
                  <p class="sort selecet">{{ index + 1 }}</p>
                  <div class="number">
                    <div class="pic">
                      <img :src="item.logoUrl" alt />
                      <div>{{ item.exchangeName }}</div>
                    </div>
                    <div style="padding-top:10px" class="current_number">
                      <peo-img :visitVolume="item.visitVolume" />
                    </div>
                  </div>
                  <div class="glob_number">
                    <p class="glob_number_top" style="color:#3333">
                      {{ item.volume24 | formateMoney }}
                    </p>
                    <p class="glob_number_bottom">
                      {{ item.assetValue | formateMoney }}
                    </p>
                  </div>
                  <div class="TF_numberN">
                    <p>
                      {{ item.assetValue | formateMoneyH }}
                    </p>
                    <ul>
                      <li>11</li>
                      <li>11</li>
                    </ul>
                  </div>
                </div>
              </div>
            </van-list>
          </template>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { hotSearch, getsearchVal, getexchanges } from "@/api/index";
import peoImg from "../../components/peo-img";
export default {
  name: "",
  data() {
    return {
      value: "",
      flag: false,
      serchShow: true,
      list: [],
      tableIndex: 0,
      switchList: "marketValue",
      currentCurrency: "USD",
      sortIndex: "",
      pageNo: 1,
      pageSize: 20,
      listSearch: [],
      totalNum: "",
      isLoading: false, // 下拉的加载图案
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noData: false, // 如果没有数据，显示暂无数据
      pageNoH: 1,
      pageSizeH: 20,
      listSearchH: [],
      totalNumH: "",
      isLoadingH: false, // 下拉的加载图案
      loadingH: false, // 当loading为true时，转圈圈
      finishedH: false, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noDataH: false, // 如果没有数据，显示暂无数据
      currencySymbol: {
        CNY: '￥',
        USD: '$',
        KRW: '₩',
        JPY: '￥'
      }
    };
  },
  components: {
    peoImg
  },
  watch: {
    value: function() {
      if (this.value != "") {
        this.flag = true;
      } else {
        this.flag = false;
        this.serchShow = true;
      }
    }
  },
  created() {
    // 判断当前语言
    // if (localStorage.getItem('currentNation')) {
    //   for (let n of this.nationList) {
    //     if (n.value == localStorage.getItem('currentNation')) {
    //       this.currentNation = n.label;
    //       this.$i18n.locale = n.value;
    //     }
    //   }
    // } else {
    //   this.currentNation = this.nationList[0].label;
    //   this.$i18n.locale = this.nationList[0].value;
    // }
    // 判断当前货币
    if (localStorage.getItem("quoteCurrencyCode")) {
      this.currentCurrency = localStorage.getItem("quoteCurrencyCode");
    } else {
      localStorage.setItem("quoteCurrencyCode", this.currentCurrency);
    }
  },
  filters: {
    toDecomal: function(value) {
      let val = value ? value : 0;
      if (val.toFixed(2) == 0.0) {
        return val;
      } else {
        let percentageVal = val.toFixed(2);
        return parseFloat(percentageVal);
      }
    },
    formateMoney: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    formateMoneyY: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${lastValue}`;
      return str;
    },
    formateMoneyG: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${lastValue}`;
      return str;
    },
    formateMoneyU: function(value) {
      let currencyCode = "USD";
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    formateMoneyH: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(4));
        lastValue = `${localeValue}`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    toDecimal(value) {
      if (value != null) {
        let val = value ? value : 0;
        if (val.toFixed(2) == 0.0) {
          return val;
        } else {
          let percentageVal = val.toFixed(2);
          return parseFloat(percentageVal);
        }
      }
    }
  },
  mounted() {
    this.gethotSearch();
  },
  computed: {
    navList: function() {
      return [
        {
          label: this.$t("币种"),
          index: 0,
          switchList: "marketValue"
        },
        {
          label: this.$t("平台"),
          index: 1,
          switchList: "hotSearch"
        }
      ];
    },
    tableTitile: function() {
      return [
        {
          label: this.$t("币种"),
          index: 0
        },
        {
          label: this.$t("最低价") + `(${this.currencySymbol[this.currentCurrency]})`,
          index: 1
        },
        {
          label: this.$t("24H涨幅"),
          index: 2
        }
      ];
    }
  },
  methods: {
    //详情页面
    godetail(item) {
      localStorage.setItem("selectCurrency", JSON.stringify(item));
      this.$router.push({
        path: "coin-detail",
        query: { currencyId: item.currencyId,symbol: item.currencyCode }
      });
    },
    godetailT(id) {
      this.$router.push({
        path: "exchange/exchange-detail",
        query: { id: id }
      });
    },
    onSearch(val) {
      this.serchShow = false;
      this.pageNo = 1;
      this.pageNoH = 1;
      this.value = val;
      this.listSearch = [];
      this.listSearchH = [];
      this.onLoad();
      this.onLoadH(1);
    },
    cancelVal() {
      this.value = "";
    },
    goBack() {
      this.$router.push("/market");
    },
    //热搜
    gethotSearch() {
      hotSearch({
        language: this.$i18n.locale,
        sortIndex: "",
        pageNo: 1,
        pageSize: 8
      })
        .then(res => {
          if (res.resultStatus) {
            this.list = res.resultData;
          }
        })
        .catch(() => {});
    },
    //选择热搜
    changeVal(val) {
      this.serchShow = false;
      this.value = val.currencyCode;
      this.pageNo = 1;
      this.pageNoH = 1;
      this.listSearch = [];
      this.listSearchH = [];
      // this.switchList = 'hotSearch';
      this.onLoad();
      this.onLoadH(1);
    },
    tabClick(item) {
      this.tableIndex = item.index;
      this.switchList = item.switchList;
    },
    getSearchVal() {
      this.noData = false;
      getsearchVal({
        language: this.$i18n.locale,
        keyword: this.value,
        pageNo: this.pageNo,
        pageSize: 20
      })
        .then(res => {
          if (res.resultStatus) {
            this.loading = false;
            this.isLoading = false;
            this.pageNo++;
            this.listSearch = this.listSearch.concat(res.resultData);
            this.totalNum = this.listSearch.length;
            if (this.totalNum == 0) {
              this.noData = true;
            }
            if (this.listSearch.length == 0 && this.pageNo === 1) {
              this.noData = true;
            }
            // 如果加载完毕，显示没有更多了
            if (res.resultData.length == 0) {
              this.finished = true;
              // this.flag = true;
            }
          }
        })
        .catch(() => {});
    },
    onLoad() {
      this.getSearchVal();
      this.loading = true;
    },
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.listSearch = [];
      this.pageNo = 1;
      this.pageSize = 20;
      this.loading = false;
      this.finished = false;
      this.noData = false;
      // 请求信息
      this.getSearchVal();
    },
    getgetexchanges() {
      this.noDataH = false;
      getexchanges({
        language: this.$i18n.locale,
        keyWords: this.value,
        pageNo: this.pageNoH,
        pageSize: 10
      })
        .then(res => {
          if (res.resultStatus) {
            this.loadingH = false;
            this.isLoadingH = false;
            this.pageNoH++;
            this.totalNumH = res.resultData.totalNum;
            for (let i = 0; i < res.resultData.dataList.length; i++) {
              this.listSearchH.push(res.resultData.dataList[i]);
            }
            if (this.totalNumH == 0) {
              this.noDataH = true;
            }
            if (this.listSearchH.length == 0 && this.pageNoH === 1) {
              this.noDataH = true;
            }
            // 如果加载完毕，显示没有更多了
            if (res.resultData.dataList.length == 0) {
              this.finishedH = true;
              // this.flag = true;
            }
          }
        })
        .catch(() => {});
    },
    onLoadH() {
      this.getgetexchanges();
      this.loadingH = true;
    },
    onRefreshH() {
      // 重新初始化这些属性
      this.isLoadingH = false;
      this.listSearchH = [];
      this.pageNoH = 1;
      this.pageSizeH = 20;
      this.loadingH = false;
      this.finishedH = false;
      this.noDataH = false;
      // 请求信息
      this.getgetexchanges();
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;

  .search_top {
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 40px;
    }   
    ::v-deep input::-webkit-input-placeholder {
      font-size: 28px;
    }
    ::v-deep input {
      font-size: 28px;
    }

    ::v-deep .van-field__left-icon .van-icon {
      font-size: 28px !important;
    }
    ::v-deep .van-field__control,
    ::v-deep .van-cell {
      line-height: 40px;
    }
  }
  .search-input {
    width: 582px;
    height: 72px;
    background: #f4f5f8;
    border-radius: 10px;
    margin: 8px 0 8px 0;
  }
  .search_close {
    position: absolute;
    top: 30px;
    right: 150px;
    img {
      width: 28px;
      height: 28px;
    }
  }
  .hotSearch {
    margin: 0 40px;
    margin-top: 32px;
    p {
      font-size: 32px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #333333;
      line-height: 45px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        font-size: 24px;
        margin-right: 20px;
        margin-top: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 33px;
        padding: 12px 40px;
        background: #f4f5f8;
        border-radius: 28px;
      }
    }
  }
  .search_val {
    .market_nav {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .nav_item {
        padding-top: 24px;
        flex: 1;
      }
      .item_off {

        font-size: 28px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
      .item_on {
   
        font-size: 28px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #007aff;
      }
      .line {
        height: 5px;
   
      }
    }
  }
  .noData {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    flex-wrap: wrap;

    img {
      width: 353px;
      height: 181px;
      margin: 214px 0 88px 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;
      line-height: 33px;
    }
  }
}
.market_table {
  // width: 375px;
  .market_table_title {
    ul {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      line-height: 33px;
      background: #eff3f5;

      .tableTitile {
        padding: 10px 0;
        margin: 0 auto;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #a4afb5;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          display: flex;
          align-items: center;
        }

        &:nth-child(2) {
          margin-left: 200px;
        }
        // &:nth-child(3) {
        //   // flex: 3;
        // }
        // &:nth-child(4) {
        //   // flex: 2;
        // }
        p {
          text-align: center;
          display: inline-block;
          margin-right: 2px;
        }

        .icon {
          margin: -12px 0;
          display: block;
        }
        .iconBlue {
          margin: -12px 0;
          display: block;
          color: #007aff;
        }
      }
    }
  }
  .table_box {
    .table_item {
      padding: 0 32px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      height: 160px;
      &:first-child {
        .selecet {
          color: #ff7600;
        }
      }
      &:nth-child(2) {
        .selecet {
          color: #f1ab19;
        }
      }
      &:nth-child(3) {
        .selecet {
          color: #96bfec;
        }
      }
      .sort {
        font-size: 36px;
        font-family: DINAlternate, DINAlternate-Bold;
        font-weight: 700;
        text-align: left;
        color: #a4afb5;
        line-height: 42px;
        margin-right: 32px;
      }
      .number {
        // flex: 3;
        // margin-right: 20px;
        .pic {
          display: flex;
          align-items: center;
          img {
            margin-right: 16px;
            width: 40px;
            height: 40px;
          }
          span {
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 40px;
          }
        }
        .current_number {
          // margin-right: 30px;
          display: flex;
          align-items: center;
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 30px;
          p {
            margin-right: 8px;
          }
        }
      }
      .glob_number {
        // flex: 4;
        .glob_number_top {
          width: 250px;
          font-size: 36px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: right;
          color: #e35e55;
          line-height: 50px;
        }
        .glob_number_top_G {
          width: 250px;
          font-size: 36px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: right;
          color: #51b463;
          line-height: 50px;
        }
        .glob_number_bottom {
          width: 250px;
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: right;
          color: #999999;
          line-height: 30px;
        }
      }
      .TF_number {
        // flex: 1;
        .colorRed {
          width: 132px;
          height: 60px;
          background: #e8726a;
          border-radius: 5px;
          font-size: 28px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 60px;
        }
        .colorGreen {
          background: #51b463;
          width: 132px;
          height: 60px;
          border-radius: 5px;
          font-size: 28px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 60px;
        }
        .colorH {
          background: #ccc;
          width: 132px;
          height: 60px;
          border-radius: 5px;
          font-size: 28px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 60px;
        }
      }
      .TF_numberN {
        // flex: 1;
        p {
          font-size: 28px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: right;
          color: #333333;
          line-height: 40px;
        }
        ul {
          display: flex;
          justify-content: flex-end;
        }
        ul {
          display: flex;
          justify-content: flex-end;
        }
        li {
          width: 40px;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #999999;
          line-height: 28px;
          border: 1px solid #dddddd;
          border-radius: 3px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
